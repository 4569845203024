// Popup.js
import React from "react";

function Popup({ message, onClose }) {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "black",
        padding: "20px",
        zIndex: 100,
        border: "1px solid #faff00",
        color: "white",
        width: "300px",
        height: "200px",
        borderRadius: "10px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        transition: "all 0.3s ease",
      }}
    >
      <p style={{ fontSize: "20px" }}>{message}</p>
      <button
        style={{
          marginTop: "10px",
          padding: "5px 10px, 5px 10px",
          backgroundColor: "#faff00",
          border: "none",
          borderRadius: "5px",
          width: "100px",
          height: "30px",
        }}
        onClick={onClose}
      >
        Close
      </button>
    </div>
  );
}

export default Popup;
