import React, { useState, useEffect } from "react";
import WelcomePage from "./welcomePage";
import { useNavigate } from "react-router-dom";
import { useData } from "./DataContext";
import Footer from "./footer";
import { useTranslation } from "react-i18next";

const brandsLogos = {
  Mercedes: "./src/assets/img/brands/mercedes.png",
  BMW: "./src/assets/img/brands/bmw.png",
  Audi: "./src/assets/img/brands/audi.webp",
  Honda: "./src/assets/img/brands/honda.png",
  Mini: "./src/assets/img/brands/mini.png",
  Porsche: "./src/assets/img/brands/porsche.png",
  Fiat: "./src/assets/img/brands/fiat.png",
  Ford: "./src/assets/img/brands/ford.png",
  Toyota: "./src/assets/img/brands/toyota.webp",
  Renault: "./src/assets/img/brands/renault.png",
  Kia: "./src/assets/img/brands/kia.webp",
};

function CarDiv() {
  const [fadeout, setFadeout] = useState(false);
  const [selectedCarId, setSelectedCarId] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [sortType, setSortType] = useState("Popular");
  const { data } = useData();

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (fadeout && selectedCarId !== null) {
      setTimeout(() => {
        navigate(`/carDetail/${selectedCarId}`);
      }, 500);
    }
  }, [fadeout, navigate, selectedCarId]);

  const handleClick = (carId) => {
    setSelectedCarId(carId);
    setFadeout(true);
  };

  const handleBrandClick = (brand) => {
    setSelectedBrand(brand);
    setSortType("");
    const carListDiv = document.getElementById("brand-container");
    if (carListDiv) {
      carListDiv.scrollIntoView({ behavior: "smooth", block: "top" });
    }
  };

  const handleClearFilters = () => {
    setSelectedBrand("");
    setSortType("");
    const carListDiv = document.getElementById("brand-container");
    if (carListDiv) {
      carListDiv.scrollIntoView({ behavior: "smooth", block: "top" });
    }
  };

  const handleSortTypeChange = (newSortType) => {
    setSortType(newSortType);
    setSelectedBrand("");  // Marka seçimini sıfırla
  };


  const calculateAverageRating = (yorumlarJson) => {
    try {
      const reviews = JSON.parse(yorumlarJson).Yorumlar;
      const totalRating = reviews.reduce(
        (acc, review) => acc + parseFloat(review[2]),
        0
      );
      return (totalRating / reviews.length).toFixed(1);
    } catch (error) {
      console.error("Yorum parse hatası:", error);
      return 0;
    }
  };

  const sortByPrice = (a, b) => {
    return sortType === "cheapToExpensive"
      ? a.price - b.price
      : b.price - a.price;
  };

  

  const sortByRating = (a, b) => {
    const ratingA = calculateAverageRating(a.yorumlarJson);
    const ratingB = calculateAverageRating(b.yorumlarJson);
    return sortType === "mostRated" ? ratingB - ratingA : ratingA - ratingB;
  };

  const sortedBrands = [...new Set(data.cars.map((car) => car.brand))];


  // brands dizisini brandsLogos anahtarlarına göre sırala
const brands = sortedBrands.sort((a, b) => {
  const keys = Object.keys(brandsLogos);
  return keys.indexOf(a) - keys.indexOf(b);
});



  const filteredAndSortedCars = data.cars
  .filter((car) => (selectedBrand ? car.brand === selectedBrand : true))
  .filter((car) => sortType === "Popular" ? car.overview.includes("The Lux Special") : true)
  .sort(
    sortType.includes("cheap") || sortType.includes("expensive")
      ? sortByPrice
      : sortByRating
  );


  return (
    <div
      style={{
        marginTop: "60px",
        opacity: fadeout ? 0 : 1,
        transition: "opacity 0.5s ease",
      }}
    >
      <WelcomePage />

      <div
        className="brand-container"
        style={{ overflowX: "auto", whiteSpace: "nowrap" }}
        id="brand-container"
      >
        <div
          key="allBrands"
          style={{
            width: "55px",
            height: "60px",
            display: "inline-block",
            margin: "15px",
            borderRight: "1px solid white",
            textAlign: "center",
            lineHeight: "55px",
            color: "white",
            backgroundColor: "#000",
            position: "sticky",
            left: "0px",
          }}
          onClick={handleClearFilters}
        >
          <p style={{ color: "white", position: "absolute", margin: "auto" }}>
            ALL
          </p>
        </div>

        {brands.map((brand) => (
          <div
            key={brand}
            style={{
              width: "55px",
              height: "55px",
              display: "inline-block",
              margin: "15px",
              border: "1px solid black",
              borderRadius: "10px",
              textAlign: "center",
              color: "white",
              backgroundImage: `url(${brandsLogos[brand]})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
            className="brand-button"
            onClick={() => handleBrandClick(brand)}
          >
            {!brandsLogos[brand] && brand}
          </div>
        ))}
      </div>

      <div className="filters">
      <button
          onClick={() => handleSortTypeChange("Popular")}
          style={{
            backgroundColor: sortType === "Popular" ? "#faff00" : "",
            color: sortType === "Popular" ? "black" : "",
          }}
        >
          <i class="fa-solid fa-wand-magic-sparkles"></i> The Lux Selected
        </button>

        <button
          onClick={() => handleSortTypeChange("cheapToExpensive")}
          style={{
            backgroundColor: sortType === "cheapToExpensive" ? "#faff00" : "",
            color: sortType === "cheapToExpensive" ? "black" : "",
          }}
        >
          <i className="fa fa-arrow-down"></i> {t("cheapToExpensive")}
        </button>
        <button
          onClick={() => handleSortTypeChange("expensiveToCheap")}
          style={{
            backgroundColor: sortType === "expensiveToCheap" ? "#faff00" : "",
            color: sortType === "expensiveToCheap" ? "black" : "",
          }}
        >
          <i className="fa fa-arrow-up"></i> {t("expensiveToCheap")}
        </button>
        <button
          onClick={() => handleSortTypeChange("mostRated")}
          style={{ backgroundColor: sortType === "mostRated" ? "#faff00" : "",
          color: sortType === "mostRated" ? "black" : "", }}
        >
          <i className="fa fa-star"></i> {t("mostRated")}
        </button>
        <button
          onClick={() => handleSortTypeChange("leastRated")}
          style={{
            backgroundColor: sortType === "leastRated" ? "#faff00" : "",
            color: sortType === "leastRated" ? "black" : "",
          }}
        >
          <i className="fa fa-star-half-alt"></i> {t("leastRated")}
        </button>
      </div>

      <div className="carListDiv" id="carListDiv">
        {filteredAndSortedCars.map((car) => (
          <div
            key={car.id}
            onClick={() => handleClick(car.id)}
            style={{ textDecoration: "none" }}
          >
            <div className="carDiv">
              <div className="carPhoto">
                <img src={car.imageUrl} alt="car" className="carCardPhoto" />
              </div>
              <div id="rateUser" style={{ color: "white" }}>
                {car.yorumlarJson ? (
                  <>
                    <i
                      className="fa-solid fa-star"
                      style={{ color: "#faff00" }}
                    ></i>
                    <span style={{ marginLeft: "5px" }}>
                      {calculateAverageRating(car.yorumlarJson)}
                    </span>
                  </>
                ) : (
                  <span>{t("noReviewsYet")}</span>
                )}
              </div>
              <div className="carBrand">
                <h3>{car.brand}</h3>
                <p>
                  {car.model} | {car.year}
                </p>
              </div>
              <div className="carDetail">
                <p>
                  <i className="fas fa-cog"></i> {car.transmission}
                </p>
                <p>
                  <i className="fas fa-gas-pump"></i> {car.fuelConsumption}
                </p>
              </div>
              <div className="carPrice">
                <h3>
                  <span>$ </span>
                  {car.price}
                </h3>
                <p> / {t("pricePerDay")}</p>
              </div>
              <button className="buttonCarDiv">
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
}

export default CarDiv;
