// src/components/SignUp.js
import React, { useState, useEffect } from "react";
import FadeIn from "./fadeIn";
import { useNavigate } from "react-router-dom";
import Popup from "./popup"; // Popup bileşenini import edin

import { useUser } from "./auth/AuthContext"; // UserContext hook'unu import edin
import { useTranslation } from 'react-i18next';
import {Link } from 'react-router-dom';

function SignUp() {
  const { user, setUser } = useUser();
  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    fullName: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/start");
      }, 3000);
    }
  }, [user, navigate]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    if (isLogin) {
      loginUser();
    } else {
      registerUser();
    }
  };
  const loginUser = () => {
    setIsLoading(true); // Yükleme başladı

    const { email, password } = formData;
    fetch("https://fuber.ltd/theluxapi/process.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "login",
        email: email,
        password: password,
      }),
    })
      .then(handleLoginResponse)
      .catch(handleError);
  };

  const registerUser = () => {
    setIsLoading(true); // Yükleme başladı

    const { fullName, email, password, phone } = formData;
    fetch("https://fuber.ltd/theluxapi/process.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "register",
        fullName: fullName,
        email: email,
        password: password,
        phone: phone,
      }),
    })
      .then(handleRegisterResponse)
      .catch(handleError);
  };

  const handleLoginResponse = (response) => {
    setIsLoading(false); // Yükleme bitti

    response.json().then((data) => {
      setShowPopup(true);
      if (data.success) {
        const userInfo = { user: data };
        setUser(userInfo);
        localStorage.setItem("user", JSON.stringify(userInfo));
        setErrorMessage("Successfully logged in.");
      } else {
        setErrorMessage(data.message || "An error occurred.");
      }
    });
  };
  const handleRegisterResponse = (response) => {
    setIsLoading(false); // Yükleme bitti

    console.log(response);
    if (response.ok && response.headers.get('Content-Type')?.includes('application/json')) {
      response.json().then((data) => {
        setShowPopup(true);
        if (data.success) {
          setErrorMessage("Successfully registered. Please log in.");
        } else {
          setErrorMessage(data.message || "Registration failed.");
        }
      }).catch((error) => {
        console.error('Error parsing JSON:', error);
        setErrorMessage("Error processing response.");
      });
    } else {
      console.error('Invalid response:', response.statusText);
      setErrorMessage("Invalid server response.");
    }
  };
  
  const handleError = (error) => {
    console.error("Error:", error);
    setShowPopup(true);
    setErrorMessage("Network error.");
  };

  if (user) {
    return (
      <FadeIn>
        <div className="signUp">
          <div
            className="signUpDiv"
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            <h1 style={{ fontWeight: "700", fontSize: "24px" }}>
              Welcome to LUX
            </h1>
            <h1 style={{ fontWeight: "200", fontSize: "24px" }}>
              {user.user.user.fullName}
            </h1>
          </div>
        </div>
      </FadeIn>
    );
  }
  if (isLoading) {
    return <div className="loader" style={{ textAlign: "center", marginTop: "50px",color:"white",fontSize:"20px",fontWeight:"700",textAlign:"center",width:"100%",height:"100vh",display:"flex",justifyContent:"center",alignItems:"center" }}>Loading...</div>; // Yükleniyor spinner'ı
  }

  return (
    <FadeIn>
      <div className="signUp">
        <div className="signUpDiv">
          <h1 style={{ fontWeight: "200" }}>
            Welcome to <span style={{ fontWeight: "700" }}>The Lux</span>
          </h1>
          <h1 style={{ fontWeight: "200", fontSize: "15px" }}>
          {t('please')}{" "}
            <span style={{ fontWeight: "700" }}>
              {isLogin ? t('login') :  t('register')}
            </span>
          </h1>
          <div className="buttonGroup">
            <button
              className={`toggleButton ${isLogin ? "active" : ""}`}
              onClick={() => setIsLogin(true)}
              style={{ borderRadius: "10px 0 0 10px" }}
            >
              {t('login')}
            </button>
            <button
              className={`toggleButton ${!isLogin ? "active" : ""}`}
              onClick={() => setIsLogin(false)}
              style={{ borderRadius: "0 10px 10px 0" }}
            >
              {t('register')}
            </button>
          </div>
          <form className="form" onSubmit={handleFormSubmit}>
            {!isLogin && (
              <>
                <input
                  type="text"
                  name="fullName"
                  placeholder="Full Name"
                  className="inputField"
                  value={formData.fullName}
                  onChange={handleFormChange}
                />

                <input
                  type="phone"
                  name="phone"
                  placeholder="Phone Number"
                  className="inputField"
                  value={formData.bornDate}
                  onChange={handleFormChange}
                />
              </>
            )}
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="inputField"
              value={formData.email}
              onChange={handleFormChange}
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              className="inputField"
              value={formData.password}
              onChange={handleFormChange}
            />
            <button type="submit" className="submitBtn">
              {isLogin ? "Login" : "Register"}
            </button>
          
              <hr />
             
            
          </form>
          {isLogin ? (<div style={{marginTop: "10px"}}> <Link to="/forgotPassword" style={{ color: "white", textDecoration: "none", marginTop: "30px", fontSize: "16px", textAlign: "center"}}>
              <i className="fa-solid fa-fish"></i> {t('forgotPassword')}
            </Link></div>
          ) : (
            <div style={{ color: "white", marginTop: "10px", fontSize: "12px", textAlign: "center"}} dangerouslySetInnerHTML={{ __html: t('fillingText') }}></div>

          )}

          {showPopup && (
            <Popup message={errorMessage} onClose={() => setShowPopup(false)} />
          )}
        </div>
      </div>
    </FadeIn>
  );
}

export default SignUp;
