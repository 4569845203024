import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function ForgotPassword() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [hashedCode, setHashedCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailDisabled, setEmailDisabled] = useState(false);
    const [codeDisabled, setCodeDisabled] = useState(true);
    const [newPasswordDisabled, setNewPasswordDisabled] = useState(true);
    const [confirmPasswordDisabled, setConfirmPasswordDisabled] = useState(true);
    const [sendCodeButtonDisabled, setSendCodeButtonDisabled] = useState(false);
    const [changePasswordButtonDisabled, setChangePasswordButtonDisabled] = useState(false);

    const handleSendCode = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://fuber.ltd/theluxapi/process.php', {
                method: 'POST',
                body: JSON.stringify({
                    email,
                    action: 'sendCode'
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setLoading(false);
            if (data.success) {
                setStatusMessage(data.message);
                setEmailDisabled(true);
                setCodeDisabled(false);
                setNewPasswordDisabled(false);
                setConfirmPasswordDisabled(false);
                setHashedCode(data.code);
                setSendCodeButtonDisabled(true);
            } else {
                setStatusMessage(data.message || 'Bir sorun oluştu');
            }
        } catch (error) {
            setLoading(false);
            setStatusMessage('Bir hata oluştu, lütfen tekrar deneyin.');
            console.error('Error:', error);
        }
    };

    const handleChangePassword = async () => {
        setLoading(true);
        try {
            if (newPassword !== confirmPassword) {
                setStatusMessage('Şifreler eşleşmiyor. Lütfen aynı şifreyi girin.');
                setLoading(false);
                return;
            }
            
            const response = await fetch('https://fuber.ltd/theluxapi/process.php', {
                method: 'POST',
                body: JSON.stringify({
                    email,
                    action: 'checkPassword',
                    hashedCode,
                    newPassword,
                    code
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setLoading(false);
            if (data.success) {
                setStatusMessage(t('passwordSuccessfullyChanged'));
                setChangePasswordButtonDisabled(true);
                setCodeDisabled(true);
                setNewPasswordDisabled(true);
                setConfirmPasswordDisabled(true);

                setTimeout(() => {
                  window.location.href = '/';
                }, 1000);
            } else {
                setStatusMessage(data.message || 'Bir sorun oluştu');
            }
        } catch (error) {
            setLoading(false);
            setStatusMessage('Bir hata oluştu, lütfen tekrar deneyin.');
            console.error('Error:', error);
        }
    };

    return (
        <div style={{ color: 'white', marginTop: '80px' , fontFamily: "poppins" }}>
            <h1>{t('forgotPassword')}</h1>
            <p style={{ fontSize: "15px", color: "white" }}>{t('pleaseEnterMailAdres')}</p>
            <input  
                style={{ 
                    color: "white", 
                    boxSizing: "border-box", 
                    marginBottom: "10px", 
                    marginTop: "10px", 
                    width: "100%", 
                    height: "46px", 
                    borderRadius: "9px", 
                    border: "1px solid white", 
                    padding: "10px", 
                    backgroundColor: "black",
                    opacity: emailDisabled || loading ? 0.5 : 1
                }}
                type="text" 
                placeholder={t('emailAddress')} 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                disabled={emailDisabled || loading}
            />
            <button 
                onClick={handleSendCode} 
                disabled={loading || sendCodeButtonDisabled} 
                style={{ 
                    color: "white", 
                    fontStyle: "bolder", 
                    fontSize: "17px",  
                    marginBottom: "10px", 
                    marginTop: "10px", 
                    width: "100%", 
                    height: "46px", 
                    borderRadius: "9px", 
                    border: "none", 
                    padding: "10px", 
                    backgroundColor: sendCodeButtonDisabled ? "#4CAF50" : "#dba100", 
                    opacity: sendCodeButtonDisabled ? 0.2 : 1 
                }}>
                {loading ? <i className="fas fa-spinner fa-spin"></i> : statusMessage === "Gönderildi" ? statusMessage : t('sendCode')}
            </button>
            <p style={{ color: "white" }}>{statusMessage}</p>
            <input 
                style={{ 
                    color: "white", 
                    boxSizing: "border-box", 
                    marginBottom: "10px", 
                    marginTop: "10px", 
                    width: "100%", 
                    height: "46px", 
                    borderRadius: "9px", 
                    border: "1px solid white", 
                    padding: "10px", 
                    backgroundColor: "black",
                    opacity: codeDisabled || loading ? 0.5 : 1
                }}
                type="text" 
                placeholder={t('code')} 
                value={code} 
                onChange={(e) => setCode(e.target.value)} 
                disabled={codeDisabled || loading}
            /><br/>
            <input 
                style={{ 
                    color: "white", 
                    boxSizing: "border-box", 
                    marginBottom: "10px", 
                    marginTop: "10px", 
                    width: "100%", 
                    height: "46px", 
                    borderRadius: "9px", 
                    border: "1px solid white", 
                    padding: "10px", 
                    backgroundColor: "black",
                    opacity: newPasswordDisabled || loading ? 0.5 : 1
                }}
                type="password" 
                placeholder={t('newPassword')} 
                value={newPassword} 
                onChange={(e) => setNewPassword(e.target.value)} 
                disabled={newPasswordDisabled || loading}
            />
            <input  
                style={{ 
                    color: "white", 
                    boxSizing: "border-box", 
                    marginBottom: "10px", 
                    marginTop: "10px", 
                    width: "100%", 
                    height: "46px", 
                    borderRadius: "9px", 
                    border: "1px solid white", 
                    padding: "10px", 
                    backgroundColor: "black",
                    opacity: confirmPasswordDisabled || loading ? 0.5 : 1
                }}
                type="password" 
                placeholder={t('confirmPassword')} 
                value={confirmPassword} 
                onChange={(e) => setConfirmPassword(e.target.value)} 
                disabled={confirmPasswordDisabled || loading}
            />
            <button 
                onClick={handleChangePassword} 
                disabled={!statusMessage || loading || changePasswordButtonDisabled}
                style={{ 
                    color: "white", 
                    fontStyle: "bolder", 
                    fontSize: "17px",  
                    marginBottom: "10px", 
                    marginTop: "10px", 
                    width: "100%", 
                    height: "46px", 
                    borderRadius: "9px", 
                    border: "none", 
                    padding: "10px", 
                    backgroundColor: "#000673", 
                    opacity: changePasswordButtonDisabled ? 0.2 : 1 
                }}>
                {loading ? <i className="fas fa-spinner fa-spin"></i> : statusMessage === "Gönderildi" ? statusMessage : t('changePassword')}
            </button>
        </div>
    );
}

export default ForgotPassword;
