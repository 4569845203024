import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "./auth/AuthContext"; // Import useUser hook

function DeleteAccount() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");

  // Use useUser hook to access user context
  const { user } = useUser();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleDeleteAccount = () => {
    if (email.trim() === "") {
      alert("Please enter a valid email address.");
      return;
    }

    if (!user || email !== user.user.user.email) {
      alert("This email is not associated with your account.");
      return;
    }

    // Post email to process.php
    fetch("https://fuber.ltd/theluxapi/process.php", {
        method: "POST",
        body: JSON.stringify({
          action: "deleteAccount",
          email: email
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json()) // Parsing the response to JSON
      .then((data) => {
        console.log(data); // Log the response data
      
        // You can now use 'data' to check the success status and display appropriate messages
        if (data.success) {
          window.location.href = "/logout";
        } else {
          alert(data.message); // Show the error message from the server
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
      
  };

  return (
    <div style={{
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "80vh",
      flexDirection: "column",
      textAlign: "center",
    }}>
      <i className="fa-solid fa-triangle-exclamation fa-fade"></i>

      <div style={{
        color: "white",
        marginTop: "10px",
        textAlign: "center",
        fontSize: "20px",
        fontWeight: "bold",
      }} dangerouslySetInnerHTML={{ __html: t("deleteAccountText") }}></div>
      <input style={{
        marginTop: "20px",
        marginBottom: "20px",
        borderRadius: "5px",
        border: "1px solid white",
        padding: "10px 20px",
        width: "100%",
        boxSizing: "border-box",
        backgroundColor: "black",
        color: "white",
        height: "46px"
      }} type="email" value={email} onChange={handleEmailChange} />
      <button style={{
        marginTop: "20px",
        backgroundColor: "red",
        padding: "10px 20px",
        borderRadius: "5px",
        border: "none",
        color: "white",
        fontWeight: "bold",
      }} onClick={handleDeleteAccount}>
        {t("deleteAccountButton")}
      </button>
      <p style={{ marginTop: "10px" }}>
        {t("emailConfirmationMessage")}
      </p>
    </div>
  );
}

export default DeleteAccount;
