import React, { useState, useEffect } from "react";
import FadeIn from "./fadeIn";
import { useUser } from "./auth/AuthContext";
import { useData } from "./DataContext";
import Modal from "react-modal";

import {
  Navigate,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

const customStyles = {
  content: {
    color: "white",
    background: "#333",
    border: "1px solid #444",
    borderRadius: "10px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "22px",
    width: "80%", // veya istediğiniz başka bir genişlik
    height: "auto", // veya istediğiniz bir yükseklik
    overflow: "auto",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)", // Overlay için koyu bir arka plan
  },
};

Modal.setAppElement("#root"); // En üst düzeydeki element ID'sini buraya girin

function checkDateDifference(pickup) {
  if (pickup) {
    const pickupDate = new Date(pickup);
    const currentDate = new Date();
    const differenceInTime = pickupDate.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    if (differenceInDays <= 5) {
      // warningMetni
      document.getElementById("warningMetni").style.display = "block";
    } else {
      document.getElementById("warningMetni").style.display = "none";
    }
  }
}

function ReserveDetail() {
  const { t } = useTranslation();

  const { user, setUser } = useUser();
  const { id } = useParams();
  const { data } = useData();
  const [pickupDateTime, setPickupDateTime] = useState("");
  const [dropoffDateTime, setDropoffDateTime] = useState("");
  const [dropoffLocation, setDropoffLocation] = useState("");
  const [carData, setCarData] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [animatedText, setAnimatedText] = useState("Are you ready?");
  const textKeys = [
    "FastPay",
    "threeDSecurity",
    "20Cheaper",
    "FreeCancellation",
    "FastService",
    "FastBooking",
  ];
  const texts = textKeys.map((key) => t(key));

  console.log(textKeys);
  console.log(texts);

  let index = 0;

  const location = useLocation();
  const [fadeout, setFadeout] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (fadeout) {
      setTimeout(() => {
        navigate("/history"); // useHistory yerine navigate kullanın
      }, 900);
    }
  }, [fadeout, navigate]);

  function kisaltMetin(metin, uzunluk) {
    if (metin.length > uzunluk) {
      return metin.substring(0, uzunluk) + "...";
    }
    return metin;
  }

  function kisaltEmail(email) {
    const emailParcalari = email.split("@");
    if (emailParcalari.length === 2) {
      return `${kisaltMetin(emailParcalari[0], 3)}@${emailParcalari[1]}`;
    }
    return email; // Geçersiz bir e-posta formatı varsa, orijinal e-postayı döndür
  }

  function createPayment(userId, pricePerDay, carName, id) {
    const pickupDate = new Date(pickupDateTime);
    const dropoffDate = new Date(dropoffDateTime);
    const differenceInTime = dropoffDate.getTime() - pickupDate.getTime();
    const totalDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    const totalPrice = totalDays * pricePerDay;
    // Gerekli alanların kontrolü
    if (!pickupDateTime || !dropoffDateTime || !dropoffLocation) {
      alert("Please fill in all the fields.");
      return;
    }
    const bookingBtnPay = document.getElementById("bookingBtnPay");
    bookingBtnPay.innerHTML = '<i class="fa-solid fa-sync fa-spin"></i>';

    const paymentUrl = `https://fuber.ltd/theluxapi/paymentOnline.php?userId=${userId}&carName=${encodeURIComponent(
      carName
    )}&totalPrice=${totalPrice}&totalDays=${totalDays}&pickupDateTime=${encodeURIComponent(
      pickupDateTime
    )}&dropoffDateTime=${encodeURIComponent(
      dropoffDateTime
    )}&dropoffLocation=${encodeURIComponent(dropoffLocation)}&carId=${id}`;

    window.location.href = paymentUrl;
  }

  useEffect(() => {
    const selectedCar = data.cars.find((car) => car.id.toString() === id);
    if (selectedCar) {
      setCarData(selectedCar);
    }
  }, [id, data.cars]);

  useEffect(() => {
    const interval = setInterval(() => {
      const element = document.getElementById("animated-text");
      if (element) {
        element.classList.remove("animate__slideIn");
        element.classList.add("animate__slideOut");
        setTimeout(() => {
          index = (index + 1) % texts.length;
          setAnimatedText(texts[index]);
          element.classList.remove("animate__slideOut");
          element.classList.add("animate__slideIn");
        }, 500);
      }
    }, 1100);
    return () => clearInterval(interval);
  }, [texts]); // `texts` dizisini dependency array'e ekleyin

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return dateString === ""
      ? "No selected"
      : `${day}.${month}.${year} ${hours}:${minutes}`;
  }

  const addBookingToHistory = (newBooking) => {
    const updatedHistory = user.user.user.eskiSiparisler
      ? [...JSON.parse(user.user.user.eskiSiparisler), newBooking]
      : [newBooking];
    setUser({
      ...user,
      user: {
        ...user.user,
        user: {
          ...user.user.user,
          eskiSiparisler: JSON.stringify(updatedHistory),
        },
      },
    });
  };

  const createBooking = async (price) => {
    // Gerekli alanların kontrolü
    if (!pickupDateTime || !dropoffDateTime || !dropoffLocation) {
      alert("Please fill in all the fields.");
      return;
    }

    // Butonun görsel güncellemesi
    const bookingButton = document.getElementById("bookingBtn");
    bookingButton.innerHTML = '<i class="fa-solid fa-sync fa-spin"></i>';
    bookingButton.style.backgroundColor = "white";

    setIsLoading(true);

    // Rezervasyon verilerinin hazırlanması

    const totalDays = Math.ceil(
      (new Date(dropoffDateTime).getTime() -
        new Date(pickupDateTime).getTime()) /
        (1000 * 3600 * 24)
    );
    const bookingData = {
      action: "newBooking",
      userId: user.user.user.id,
      carId: id,
      pickupDateTime,
      dropoffDateTime,
      dropoffLocation,
      totalPrice: price * totalDays,
    };

    try {
      // API isteğinin yapılması
      const response = await fetch("https://fuber.ltd/theluxapi/process.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bookingData),
      });

      // Sunucu yanıtının kontrolü
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Yanıtın JSON olarak alınması
      const result = await response.json();

      // Başarılı sonuç kontrolü
      if (result.success === true) {
        // Kullanıcı arayüzünün güncellenmesi
        bookingButton.innerHTML =
          'Success!<br/>Redirecting <i class="fa-solid fa-spinner fa-spin-pulse"></i>';
        bookingButton.style.backgroundColor = "green";
        bookingButton.style.color = "white";
        setTimeout(() => {
          setFadeout(true);
        }, 1000);
      } else {
        throw new Error(result.message || "Booking failed");
      }
    } catch (error) {
      console.error("Booking creation failed:", error);
      alert("Booking creation failed: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FadeIn>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Simple Modal"
        // Burada modalınıza özel stil tanımlamaları yapabilirsiniz
        style={{
          overlay: {
            backgroundColor: "black", // Siyah overlay rengi
          },
          content: {
            color: "white",
            background: "#333",
            border: "1px solid #444",
            borderRadius: "10px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "22px",
            width: "80%", // veya istediğiniz başka bir genişlik
            height: "70%", // veya istediğiniz bir yükseklik
            overflow: "auto",
          },
        }}
      >
        <div>
          <p dangerouslySetInnerHTML={{ __html: t("warningText") }}></p>
          <button
            style={{
              marginTop: "10px",
              backgroundColor: "white",
              color: "black",
              border: "none",
              padding: "5px 10px",
              borderRadius: "5px",
              cursor: "pointer",
              height: "30px",
              width: "100px",
            }}
            onClick={() => setModalIsOpen(false)}
          >
            Close
          </button>
        </div>
      </Modal>
      <div
        className="carDetailPage"
        style={{ marginTop: "40px", padding: "20px", boxSizing: "border-box" }}
      >
        <h1
          style={{
            marginTop: "20px",
            color: "white",
            fontSize: "1.2rem",
            textAlign: "center",
          }}
        >
          {t("rentACar")}{" "}
          <span style={{ fontWeight: "300" }}> {t("anytimeAnywhere")} </span>
        </h1>
        <hr />
        <p
          style={{
            color: "white",
            fontSize: "0.8rem",
            textAlign: "center",
            fontStyle: "italic",
            margin: "0px",
          }}
        >
          {t("reserveYourCar")}
        </p>
        <div
          className="carDetailPageCarImage"
          style={{
            marginTop: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0px",
          }}
        >
          {carData && (
            <>
              <img
                src={JSON.parse(carData.imgJson).gorseller[0]}
                alt="Car"
                style={{ maxWidth: "60%", height: "auto" }}
              />
              <p
                style={{
                  fontSize: "1.8rem",
                  textAlign: "left",
                  margin: "0px",
                  fontWeight: "700",
                  marginTop: "0px",
                  lineHeight: "32px",
                  marginLeft: "0px",
                }}
              >
                {carData.brand}
                <br />
                <span
                  style={{
                    color: "#faff00",
                    WebkitTextFillColor: "transparent",
                    WebkitTextStroke: "1px #faff00",
                  }}
                >
                  {carData.model}
                </span>
              </p>
            </>
          )}
        </div>
        <div
          className="row"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div className="col-md-6">
            <div style={{ marginTop: "20px" }}>
              <label style={{ fontSize: "15px" }}> {t("pickupDate")} :</label>
              <br />
              <input
                type="datetime-local"
                min={new Date().toISOString().slice(0, 16)}
                className="form-control dropinput"
                value={pickupDateTime}
                onChange={(e) => {
                  setPickupDateTime(e.target.value);
                  checkDateDifference(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div style={{ marginTop: "20px" }}>
              <label style={{ fontSize: "15px" }}>{t("dropOffDate")}:</label>
              <br />

              <input
                type="datetime-local"
                min={pickupDateTime}
                className="form-control dropinput"
                value={dropoffDateTime}
                onChange={(e) => {
                  setDropoffDateTime(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="col-md-12" style={{ width: "100%" }}>
          <div style={{ marginTop: "20px", width: "100%" }}>
            <label style={{ fontSize: "15px" }}>
              {t("pickupAndDropOffLocation")}:
            </label>
            <br />
            <select
              className="form-control dropinput"
              value={dropoffLocation}
              onChange={(e) => setDropoffLocation(e.target.value)}
              style={{ width: "100%" }}
            >
              <option value="No Selected">
                {t("pickupAndDropOffLocation")}
              </option>
              <option value="ANTALYA - GZP">
                ANTALYA - GZP | Gazipasa - Alanya Airport
              </option>
              <option value="KAYSERI - ASR">
                KAYSERI - ASR | Erkilet Airport
              </option>
            </select>
            <div
              id="warningMetni"
              style={{ display: "none", marginTop: "30px" }}
              onClick={() => setModalIsOpen(true)}
            >
              <hr />
              <i
                style={{
                  fontSize: "20px",
                  color: "red",
                  textAlign: "center",
                  marginTop: "0px",
                  width: "100%",
                }}
                className="fa-solid fa-triangle-exclamation fa-fade"
              ></i>
              <p
                style={{
                  marginTop: "0px",
                  fontSize: "0.75rem",
                  textAlign: "center",
                  color: "red",
                }}
                dangerouslySetInnerHTML={{ __html: t("vehicleRentinfo2") }}
              ></p>
            </div>

            <hr />
            <i
              style={{
                fontSize: "20px",
                color: "white",
                textAlign: "center",
                marginTop: "0px",
                width: "100%",
              }}
              className="fa-regular fa-bell fa-shake"
            ></i>
            <p
              style={{
                marginTop: "3px",
                fontSize: "13px",
                textAlign: "center",
              }}
            >
              {t("vehicleRentinfo")}
            </p>
          </div>
        </div>
        <div
          className="carDetailPageContent"
          style={{
            marginTop: "0px",
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: "0.85rem",
              textAlign: "left",
              lineHeight: "0px",
              fontWeight: "700",
            }}
          >
            <i className="fad fa-check-circle fa-verified"></i>{" "}
            {t("renterInformation")}:
          </p>
          <p
            style={{
              fontSize: "0.85rem",
              lineHeight: "0px",
              textAlign: "center",
              marginTop: "0px",
            }}
          >
            <i className="fad fa-user"></i> {user.user.user.fullName}
            <br />
          </p>
          <div
            style={{
              display: "flex",
              gap: "15px",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "0px",
              fontSize: "0.85rem",
            }}
          >
            <div>
              <i className="fad fa-phone"></i> {user.user.user.phone}
            </div>
            <div>
              <i className="fad fa-envelope"></i>{" "}
              {kisaltEmail(user.user.user.email)}
            </div>
          </div>
          <hr />
          <p
            style={{
              color: "white",
              fontSize: "0.85rem",
              textAlign: "left",
              lineHeight: "0px",
              fontWeight: "700",
            }}
          >
            <i className="fad fa-check-circle fa-verified"></i>{" "}
            {t("appointmentInformation")}:
          </p>
          <p
            style={{
              fontSize: "0.85rem",
              lineHeight: "0px",
              textAlign: "center",
              marginTop: "0px",
              marginBottom: "0px",
            }}
          >
            <i className="fad fa-car"></i>{" "}
            {carData
              ? `${carData.brand} ${carData.model} | ${carData.year}`
              : ""}
            <br />
          </p>
          <div
            style={{
              display: "flex",
              gap: "15px",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "15px",
              fontSize: "0.85rem",
            }}
          >
            <div>
              <i className="fad fa-clock"></i> {formatDate(pickupDateTime)}
            </div>
            <div>
              <i className="fa-solid fa-location-crosshairs"></i>{" "}
              {dropoffLocation ? dropoffLocation : "No selected"}
            </div>
          </div>
          <hr />
        </div>
        <div
          className="ButtonGroup"
          style={{
            width: "100%",
            marginTop: "0px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <button
            className="submitBtn"
            id="bookingBtn"
            style={{ marginTop: "20px", width: "100%", fontSize: "12px" }}
            onClick={() => createBooking(carData ? carData.price : 0)}
          >
            {t("createBooking")}
            <br />
            {carData ? `$${(carData.price / 1).toFixed(2)}` : ""} /day
          </button>

          <button
            id="bookingBtnPay"
            onClick={() =>
              createPayment(
                user.user.user.id,
                (carData.price / 1.07).toFixed(2),
                carData.brand + " " + carData.model,
                carData.id
              )
            }
            className="submitBtn"
            style={{
              marginTop: "20px",
              width: "100%",
              backgroundColor: "#00357a",
              color: "white",
              position: "relative",
              fontSize: "13px",
            }}
          >
            <span id="animated-text" className="animate__slideIn payNow">
              <i className="fa-solid fa-wand-magic-sparkles"></i> {animatedText}
            </span>
            <i className="fa-solid fa-file-invoice-dollar"></i> {t("payNow")}
            <br />
            {carData ? `$${(carData.price / 1.07).toFixed(2)}` : ""} /day
          </button>
        </div>
      </div>
    </FadeIn>
  );
}

export default ReserveDetail;
