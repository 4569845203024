import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function Footer() {
  const location = useLocation();
  const [fadeout, setFadeout] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (fadeout) {
      setTimeout(() => {
        navigate("/carDetail"); // useHistory yerine navigate kullanın
      }, 500);
    }
  }, [fadeout, navigate]);

  const handleClick = () => {
    setFadeout(true);
    
  };


  // Mevcut konumu konsolda yazdır
  console.log("Current Path:", location.pathname);

  const handleIconClick = () => {
    handleClick();
    if (location.pathname === '/start') {
      navigate('/profile');
    } else if (location.pathname === '/profile') {
      navigate('/start');
    }
  };

  const iconClass = location.pathname === '/start' ? 'fa-solid fa-user' : 'fa-solid fa-car';

  return (
    <div className="footer">
      <div className="floatingButton" onClick={handleIconClick}>
        <i className={iconClass}></i>
      </div>
    </div>
  );
}

export default Footer;
