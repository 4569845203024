import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Dil dosyalarınızı burada import edin
// Örnek olarak İngilizce ve Rusça için
import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';
import translationTR from './locales/tr/translation.json';

// Dil kaynaklarınızı burada tanımlayın
const resources = {
  en: {
    translation: translationEN
  },
  ru: {
    translation: translationRU
  },
  tr: {
    translation: translationTR
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", // Varsayılan dil
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
