import React, { useRef, useState, useEffect } from "react";
import StartUpSound from "../assets/sound/startup.mp3";
import { useNavigate } from "react-router-dom"; // useNavigate'i import edin
import { useTranslation } from 'react-i18next';


function Intro() {
    const carRef = useRef(null);
    const speedometerLineRef = useRef(null);
    const speedometerNumberRef = useRef(null);
    const [currentSpeed, setCurrentSpeed] = useState(0);
    const currentSpeedRef = useRef(currentSpeed);
    const navigate = useNavigate(); // useNavigate'i kullanarak navigate fonksiyonunu tanımlayın

    const { t } = useTranslation();

    useEffect(() => {
        currentSpeedRef.current = currentSpeed;
        if (currentSpeed === 100) {
          // id'si 'introDiv' olan div'in opacity'sini azalt

          setTimeout(() => {
            const introDiv = document.getElementById('introDiv');
            if (introDiv) {
              introDiv.style.transition = 'opacity 1s ease';
              introDiv.style.opacity = '0';
            }
          }, 1600); // 2 saniye bekle


         
    
          // Belirli bir süre bekledikten sonra /start sayfasına yönlendir
          setTimeout(() => {
            navigate('/signUp');
          }, 2500); // 2 saniye bekle
        }
      }, [currentSpeed, navigate]);
    

  function getStarted() {
    var audio = document.getElementById("startup");
    
    audio.play();


    
    var car = carRef.current;
    var bgSpeedometer = document.getElementById("bgSpeedometer");
    var speedometerLine = speedometerLineRef.current;
    


    bgSpeedometer.style.display = "block";

    car.style.animation = "car-animation 1s linear";
    car.style.animationFillMode = "forwards";

    speedometerLine.style.animation = "speedometer-line-animation 1.5s linear";
    speedometerLine.style.animationFillMode = "forwards";

    animateSpeed();
  }

  function animateSpeed() {
    const speedometerNumber = speedometerNumberRef.current;

    const animationInterval = setInterval(() => {
      if (currentSpeedRef.current < 100) {
        setCurrentSpeed((prevSpeed) => prevSpeed + 1);
      } else {
        clearInterval(animationInterval);
      }
    }, 15);

    speedometerNumber.style.animation = "none";
    setTimeout(() => {
      speedometerNumber.style.animation = "speedometer-number-animation 1.5s linear";
      speedometerNumber.style.animationFillMode = "forwards";
      var buttonIntro = document.getElementById("buttonIntro");
      buttonIntro.style.display = "none";
    }, 0);
  }

  return (
    <div className="intro" id="introDiv">
    <audio id="startup" src={StartUpSound} style={{ display: "none" }}></audio>
    
    <div style={{ color: "white", marginTop: "80px" }} dangerouslySetInnerHTML={{ __html: t('introHead') }}></div>
      
    <div
      className="carImg"
      ref={carRef}
      style={{ transition: "transform 1s ease" }}
    ></div>
    <div className="buttonIntro" id="buttonIntro" onClick={getStarted}>
      {t('getStarted')}
    </div>
    <div className="bgSpeedometer" id="bgSpeedometer">
      <div className="speedometerLine" ref={speedometerLineRef}></div>
      <div className="speedometerNumber" ref={speedometerNumberRef}>
        {currentSpeed}<span className="percent">%</span>
      </div>
    </div>
  </div>
  
  );
}

export default Intro;
