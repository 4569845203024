import React, { useEffect, useState } from "react";
import Logo from "../assets/img/logo.svg";
import { useUser } from "./auth/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation, I18nextProvider } from "react-i18next"; // Import I18nextProvider
import RussiaFlag from "./../assets/img/ru.png";
import UKFlag from "./../assets/img/en.png";
import TurkeyFlag from "./../assets/img/tr.png"; // Türk bayrağı resmi

import i18n from "i18next"; // Import i18n instance

function Header() {
  const { user, logout } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language); // Use i18n.language instead of t.language
  const [isLanguageSelected, setIsLanguageSelected] = useState(false); // State to track if language is manually selected

  const [currentLocation, setCurrentLocation] = useState("Yükleniyor...");

  useEffect(() => {
    const fetchLocationFromIP = async () => {
      const url = "https://ipapi.co/json/";
      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data && data.languages) {
          setCurrentLocation(`${data.city}, ${data.country_name}`);
          const primaryLanguageCode = data.languages
            .split(",")[0]
            .split("-")[0]; // Örn: "tr-TR" -> "tr"
          // Desteklenen diller: tr, en, ru
          const supportedLanguages = ["tr", "en", "ru"];
          const languageToSet = supportedLanguages.includes(primaryLanguageCode)
            ? primaryLanguageCode
            : "en";
          if (!isLanguageSelected) {
            changeLanguage(languageToSet);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchLocationFromIP();
  }, [language, isLanguageSelected]);
  
  const isCarDetailPage = location.pathname.startsWith("/carDetail/");
  const isReserveDetailPage = location.pathname.startsWith("/reserveDetail/");
  const isProfilePage = location.pathname === "/profile";
  const isHistoryPage = location.pathname === "/history";
  const isChatPage = location.pathname === "/liveChat";

  const goBack = () => {
    window.scrollTo(0, 0);
    navigate(-1);
  };

  const goMain = () => {
    window.scrollTo(0, 0);
    navigate("/start");
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Use i18n.changeLanguage instead of t.changeLanguage
    setLanguage(lng);
    setIsLanguageSelected(true); // Set language selection status to true
  };

  const share = () => {
    console.log("Paylaşım işlevi burada olabilir");
  };

  return (
    <header
      className="header"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {isCarDetailPage ? (
        // Car Detail Sayfası için içerik
        <>
          <div style={{ color: "white", fontSize: "23px" }} onClick={goBack}>
            <i className="fas fa-arrow-left"></i>
          </div>
          <h2 style={{ margin: "0", color: "white" }}>{t("carDetails")}</h2>
          <div style={{ color: "white", fontSize: "23px" }} onClick={share}>
            <img
              onClick={() =>
                changeLanguage(
                  language === "ru" ? "en" : language === "en" ? "tr" : "ru"
                )
              }
              style={{ width: "40px", height: "auto" }}
              src={
                language === "ru"
                  ? RussiaFlag
                  : language === "en"
                  ? UKFlag
                  : TurkeyFlag
              }
              alt={
                language === "ru"
                  ? "Russian"
                  : language === "en"
                  ? "English"
                  : "Turkish"
              }
            />
          </div>
        </>
      ) : isReserveDetailPage ? (
        // Reserve Detail Sayfası için içerik
        <>
          <div style={{ color: "white", fontSize: "23px" }} onClick={goBack}>
            <i className="fas fa-arrow-left"></i>
          </div>
          <h2 style={{ margin: "0", color: "white" }}>{t("rentDetails")}</h2>
          <div style={{ color: "white", fontSize: "23px" }} onClick={share}>
            <img
              onClick={() =>
                changeLanguage(
                  language === "ru" ? "en" : language === "en" ? "tr" : "ru"
                )
              }
              style={{ width: "40px", height: "auto" }}
              src={
                language === "ru"
                  ? RussiaFlag
                  : language === "en"
                  ? UKFlag
                  : TurkeyFlag
              }
              alt={
                language === "ru"
                  ? "Russian"
                  : language === "en"
                  ? "English"
                  : "Turkish"
              }
            />
          </div>
        </>
      ) : isProfilePage ? (
        // Profil Sayfası için içerik
        <>
          <h2 style={{ margin: "0", color: "white" }}>{t("profile")}</h2>
          <div style={{ color: "white", fontSize: "23px" }} >
            <img
              onClick={() =>
                changeLanguage(
                  language === "ru" ? "en" : language === "en" ? "tr" : "ru"
                )
              }
              style={{ width: "40px", height: "auto" }}
              src={
                language === "ru"
                  ? RussiaFlag
                  : language === "en"
                  ? UKFlag
                  : TurkeyFlag
              }
              alt={
                language === "ru"
                  ? "Russian"
                  : language === "en"
                  ? "English"
                  : "Turkish"
              }
            />
          </div>
        </>
      ) : isHistoryPage ? (
        <>
          <div style={{ color: "white", fontSize: "23px" }} onClick={goMain}>
            <i className="fas fa-arrow-left"></i>
          </div>
          <h2 style={{ margin: "0", color: "white" }}> {t("history")}</h2>
          <div style={{ color: "white", fontSize: "23px" }} onClick={share}>
            <img
              onClick={() =>
                changeLanguage(
                  language === "ru" ? "en" : language === "en" ? "tr" : "ru"
                )
              }
              style={{ width: "40px", height: "auto" }}
              src={
                language === "ru"
                  ? RussiaFlag
                  : language === "en"
                  ? UKFlag
                  : TurkeyFlag
              }
              alt={
                language === "ru"
                  ? "Russian"
                  : language === "en"
                  ? "English"
                  : "Turkish"
              }
            />
          </div>
        </>
      ) : isChatPage ? (
        <>
          <div style={{ color: "white", fontSize: "23px" }} onClick={goBack}>
            <i className="fas fa-arrow-left"></i>
          </div>
          <h2 style={{ margin: "0", color: "white" }}> {t("liveChat")}</h2>
          <div style={{ color: "white", fontSize: "23px" }} onClick={share}>
            <img
              onClick={() =>
                changeLanguage(
                  language === "ru" ? "en" : language === "en" ? "tr" : "ru"
                )
              }
              style={{ width: "40px", height: "auto" }}
              src={
                language === "ru"
                  ? RussiaFlag
                  : language === "en"
                  ? UKFlag
                  : TurkeyFlag
              }
              alt={
                language === "ru"
                  ? "Russian"
                  : language === "en"
                  ? "English"
                  : "Turkish"
              }
            />
          </div>
        </>
      ) : (
        // Diğer durumlar için varsayılan içerik
        <>
          <img src={Logo} alt="thelux" className="logo" />

          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <p className="title">
              <i className="fa-solid fa-location-dot"></i> {currentLocation}
            </p>
            <img
              onClick={() =>
                changeLanguage(
                  language === "ru" ? "en" : language === "en" ? "tr" : "ru"
                )
              }
              style={{ width: "40px", height: "auto" }}
              src={
                language === "ru"
                  ? RussiaFlag
                  : language === "en"
                  ? UKFlag
                  : TurkeyFlag
              }
              alt={
                language === "ru"
                  ? "Russian"
                  : language === "en"
                  ? "English"
                  : "Turkish"
              }
            />
          </div>
        </>
      )}
    </header>
  );
}

export default Header;
