import React, { createContext, useState, useEffect, useContext } from 'react';

const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    if (user) {
      console.log(user);
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  const login = (userData) => {
    setUser(userData);
  };

  const logout = () => {
    localStorage.removeItem('user'); // Kullanıcıyı local storage'dan çıkar
    setUser(null); // User state'ini null yap
  };


  // useUser hook içinde
const addBookingToHistory = (newBooking) => {
  setUser(prevUser => {
    const updatedHistory = [...prevUser.user.user.eskiSiparisler, newBooking];
    return { ...prevUser, user: { ...prevUser.user, user: { ...prevUser.user.user, eskiSiparisler: updatedHistory } } };
  });
};




  return (
    <UserContext.Provider value={{ user, setUser, login, logout, addBookingToHistory}}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
