import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from "./AuthContext";

function Logout() {
    const { logout } = useUser();
    const navigate = useNavigate();

    setTimeout(() => {
        logout();
        navigate("/login"); 
    }, 3000);

    return (<>
    <div style={{ textAlign: "center", marginTop: "100px",display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
        <h1 style={{ fontSize: "24px", fontWeight: "700",color: "red" }}>Successfully logged out.</h1>
        <i style={{ fontSize: "40px", color: "red" }} class="fa-solid fa-lock fa-shake"></i>
        <i style={{ fontSize: "20px", color: "white", marginTop: "10px" }}>You will be redirected to the login page in 3 seconds...</i>

    </div>
    </>
        
    );
}

export default Logout;
