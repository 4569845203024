import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function Whatsapp() {
    const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = 'https://wa.me/905448539352?text=Hello';
    }, 3000);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "80vh", flexDirection: "column", textAlign: "center"}}>
      <p style={{fontSize: "20px", fontWeight: "bold", color: "white"}}>
      {t('redirectingToWhatsApp')}
      </p>
      <p style={{fontSize: "15px", color: "white"}}>
      {t('representativeName')}
      </p>
      <p style={{fontSize: "20px", fontWeight: "bold", color: "white"}}>
      {t('communicationLanguages')}
      </p>
      <img style={{width: "75px"}} src='https://fuber.ltd/theluxapi/1_e_Loq49BI4WmN7o9ItTADg.gif' alt='Loading'></img>
    </div>
  )
}

export default Whatsapp;
