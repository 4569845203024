import React, { useEffect, useState } from 'react';
import CarDiv from './carDiv';

function Start() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Sayfa yüklendiğinde 100ms sonra fade-in animasyonunu başlat
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);
  
 
  

  return (
    <div  className={`fade-in ${isVisible ? 'visible' : ''}`}>
       <CarDiv />

    </div>
  );
}

export default Start;
