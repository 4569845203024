import React, { useState, useEffect } from "react";
import Footer from "./footer";
import FadeIn from "./fadeIn";
import { useUser } from "./auth/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { FastAverageColor } from 'fast-average-color';

import { useTranslation } from 'react-i18next';



import md5 from 'md5';

function kisaltMetin(metin, uzunluk) {
  if (metin.length > uzunluk) {
    return metin.substring(0, uzunluk) + '...';
  }
  return metin;
}

function kisaltEmail(email) {
  const emailParcalari = email.split('@');
  if (emailParcalari.length === 2) {
    return `${kisaltMetin(emailParcalari[0], 8)}@${emailParcalari[1]}`;
  }
  return email; // Geçersiz bir e-posta formatı varsa, orijinal e-postayı döndür
}






function Profile() {
  const { user,setUser } = useUser();
  const [boxShadowColor, setBoxShadowColor] = useState('red'); // Varsayılan renk
  const { t } = useTranslation();

  

  const [fullName, setFullName] = useState(user.user.user.fullName);
  const [phone, setPhone] = useState(user.user.user.phone);
  const [email, setEmail] = useState(user.user.user.email);

  const location = useLocation();

  const navigate = useNavigate();
  const isProfilePage = location.pathname === "/profile";

  const [isFlipped, setIsFlipped] = useState(false);

  const emailHash = md5(email.trim().toLowerCase());

  // const profilePhoto = `https://www.gravatar.com/avatar/${emailHash}`;
  const profilePhoto = `https://api.dicebear.com/7.x/initials/svg?seed=${fullName.replace(/\s+/g, '')}`;
console.log(profilePhoto);
  useEffect(() => {
    const fac = new FastAverageColor();
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = profilePhoto;

    img.onload = () => {
      const color = fac.getColor(img);
      console.log(color);
      setBoxShadowColor(`${color.hex}`);
    };
  }, [profilePhoto]);


  const goToHistory = () => {
    if (isProfilePage) {
      navigate("/history");
    }
  }


  const goToWhatsapp = () => {
    navigate("/whatsapp");
  }

  const goToDelete = () => {
    navigate("/deleteAccount");
  }



  const goToHelpDesk = () => {
    if (isProfilePage) {
      navigate("/liveChat");
    }
  }

  const flipCard = () => {
    var editButton = document.getElementById("editButton");
    setIsFlipped(!isFlipped);
    if (isFlipped) {
      var newName = document.getElementById("newName").value;
      var newPhone = document.getElementById("newPhone").value;
      var newEmail = document.getElementById("newEmail").value;
      // API'ye istek gönder
     // API isteği ve durum güncellemesi
fetch("https://fuber.ltd/theluxapi/process.php", {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify({
    action: "userUpdate",
    email: newEmail,
    fullName: newName,
    phone: newPhone,
    userId: user.user.user.id,
  }),
})
.then((response) => response.json())
.then((data) => {
  console.log(data);
  // API'den dönen güncellenmiş bilgiler ile durumu güncelle
  setFullName(newName);
  setPhone(newPhone);
  setEmail(newEmail);

  // UserContext içindeki setUser kullanarak kullanıcı bilgilerini güncelle
  setUser(prevUser => ({
    ...prevUser,
    user: {
      ...prevUser.user,
      user: {
        ...prevUser.user.user,
        fullName: newName,
        email: newEmail,
        phone: newPhone,
      }
    }
  }));

  // Düğme stillerini ve metnini güncelle
  editButton.style.backgroundColor = "rgb(0, 35, 104)";
  editButton.style.color = "white";
  editButton.innerHTML = '<i class="fa-solid fa-floppy-disk"></i> Saved  <i class="fa-solid fa-circle-check"></i>';
})
.catch((error) => {
  console.error("Error:", error);
});

    } else {
      editButton.style.backgroundColor = "#faff00";
      editButton.style.color = "black";
      editButton.innerHTML =
        '<i class="fa-solid fa-triangle-exclamation fa-fade"></i> Save <i class="fa-solid fa-triangle-exclamation fa-fade"></i>';
    }
  };

  console.log(user);

  const handleLogout = () => {
    navigate("/logout"); 
  };

  const formattedDate = new Date(user.user.user.kayitTarihi).toLocaleDateString(
    "tr-TR",
    {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }
  );

  const formattedBornDate = new Date(
    user.user.user.dogumTarihi
  ).toLocaleDateString("tr-TR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  return (
    <FadeIn>
      <div className="carDetailPage" style={{ marginTop: "70px" }}>
        <div className="profile">
        <div
      className="profileImg"
      style={{
        backgroundImage: `url(${profilePhoto})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "90px",
        height: "90px",
        borderRadius: "50%",
        boxShadow: `0 0 16px ${boxShadowColor}`,
        border: `2px solid ${boxShadowColor}`,
      }}
    >
    </div>
  
        </div>
  
        <h1
          style={{
            marginTop: "10px",
            fontWeight: "800",
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          {user.user.user.fullName}
        </h1>
        <p style={{ marginTop: "-15px", fontSize: "14px", lineHeight: "0px" }}>
        {t('registerDate')} <span style={{ color: "red" }}> | </span>{" "}
          {formattedDate}
        </p>
        {user.user.user.status === "verified" ? (
  <p style={{ marginTop: "0px", fontSize: "14px", lineHeight: "0px", fontWeight: "700" }}>
    <i className="fad fa-check-circle fa-verified"></i> Verified Account
  </p>
) : (
  <></>
)}

      

        <div
          className="cardContainer"
          style={{ marginTop: "10px", width: "100%" }}
        >
          <div
            className={`card ${isFlipped ? "is-flipped" : ""}`}
            style={{ width: "100%" }}
          >
            <div className="cardFace cardFront">
              <div
                className="profileContent"
                style={{
                  width: "100%",
                  maxWidth: "400px",
                  backgroundColor: "#1E1E1E",
                  borderRadius: "12px",
                  padding: "14px",
                  boxSizing: "border-box",
                }}
              >
                <section style={{ marginBottom: "10px" }}>
                  <h2
                    style={{
                      borderBottom: "1px solid red",
                      paddingBottom: "0px",
                    }}
                  >
                    {t('personalInformation')}
                  </h2>
                  <div style={{ marginTop: "10px" }}>
                    <p>
                      <i
                        className="fad fa-envelope fa-duotone"
                        style={{ marginRight: "10px" }}
                      ></i>
                      <strong>{t('email')}:</strong> {kisaltEmail(user.user.user.email)}
                    </p>
                    <p>
                      <i
                        className="fad fa-phone fa-duotone"
                        style={{ marginRight: "10px" }}
                      ></i>
                      <strong>{t('phoneNumber')}:</strong> {user.user.user.phone}
                    </p>
                  </div>
                </section>
                <section>
                  <h2
                    style={{
                      borderBottom: "1px solid red",
                      paddingBottom: "5px",
                    }}
                  >
                    {t('utilities')}
                  </h2>
                  <div style={{ marginTop: "10px" }}>
                    <p onClick={goToHistory}>
                      <i
                        className="fad fa-history fa-duotone"
                        style={{ marginRight: "10px" }}
                      ></i>
                         {t('history')}
                    </p>
                    <p onClick={goToHelpDesk}>
                      <i
                        className="fad fa-question-circle fa-duotone"
                        style={{ marginRight: "10px" }}
                      ></i>
                        {t('askHelpDesk')}
                    </p>
                    <p onClick={goToWhatsapp}>
                    <i className="fab fa-whatsapp" style={{ marginRight: "10px" }}></i>

                    {t('whatsappHelp')}
                    </p>
                    <p onClick={goToDelete}>
                    <i class="fa-solid fa-triangle-exclamation fa-fade" style={{ marginRight: "10px" }}></i>
                    {t('deleteAccountLabel')}
                    </p>
                  </div>
                </section>
              </div>
            </div>

            <div
              className="cardFace cardBack"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "-20px",
              }}
            >
              <div
                className="profileContent"
                style={{
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <label
                  htmlFor="edit"
                  style={{
                    marginBottom: "10px",
                    marginTop: "10px",
                    fontWeight: "700",
                    fontSize: "18px",
                  }}
                >
                     {t('yourName')}
                </label>
                <input
                  id="newName"
                  className="inputField"
                  style={{
                    width: "100%",
                    maxWidth: "300px",
                    marginBottom: "10px",
                    minWidth: "220px",
                  }}
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />{" "}
              </div>
              <div
                className="profileContent"
                style={{
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <label
                  htmlFor="edit"
                  style={{
                    marginBottom: "10px",
                    marginTop: "10px",
                    fontWeight: "700",
                    fontSize: "18px",
                  }}
                >
                  {t('phoneNumber')}
                </label>
                <input
                  id="newPhone"
                  className="inputField"
                  style={{
                    width: "100%",
                    maxWidth: "300px",
                    marginBottom: "10px",
                    minWidth: "220px",
                  }}
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />{" "}
              </div>
              <div
                className="profileContent"
                style={{
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <label
                  htmlFor="edit"
                  style={{
                    marginBottom: "10px",
                    marginTop: "10px",
                    fontWeight: "700",
                    fontSize: "18px",
                  }}
                >
                  {t('email')}
                </label>
                <input
                  id="newEmail"
                  className="inputField"
                  style={{
                    width: "100%",
                    maxWidth: "300px",
                    marginBottom: "10px",
                    minWidth: "220px",
                  }}
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />{" "}
              </div>
              
            </div>
          </div>
        </div>

        {isProfilePage && user && (
          <div
            className="logoutButtonContainer"
            style={{
              display: "flex",
              width: "88%",
              justifyContent: "left",
              alignItems: "left",
              marginTop: "20px",
              gap: "0px",
              zIndex: "10",
              marginBottom: "30px",
              flexDirection: "column",
            }}
          >
            <button id="editButton" onClick={flipCard} className="editButton">
            {t('edit')} <i className="fa fa-edit"></i>
            </button>
            <button onClick={handleLogout} className="logoutButton">
              <i className="fa fa-sign-out"></i> {t('logout')}
            </button>
          </div>
        )}
        <Footer />
      </div>
    </FadeIn>
  );
}

export default Profile;
