import React, { useEffect, useState } from 'react';

function LiveChat() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // iframe yüklendikten sonra gerekli stil değişikliklerini yap
    const applyStyles = () => {
        // "tawk-chat-panel tawk-custom-flex-1 ps--active-y" class'ını seç ve overflow'u hidden yap
        const chatPanel = document.querySelector(".tawk-chat-panel.tawk-custom-flex-1.ps--active-y");
        if (chatPanel) {
          chatPanel.style.overflow = 'hidden';
        }
  
        // "tawk-flex tawk-flex-center tawk-text-center tawk-padding-small" class'ını seç ve display'i none yap
        const elements = document.querySelectorAll(".tawk-flex.tawk-flex-center.tawk-text-center.tawk-padding-small");
        elements.forEach(element => {
          element.style.display = 'none';
        });
      };

    window.addEventListener('load', applyStyles);

    return () => {
      window.removeEventListener('load', applyStyles);
    };
  }, []);

  return (
    <div style={{ width: '100%', height: '100%', position: 'fixed', left: '0', bottom: '0', border: 'none', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {loading && (
        <div style={{ position: 'fixed', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 1)',color: 'white' }}>
          Loading...
        </div>
      )}
      <iframe 
        src="https://tawk.to/chat/65cd22cd9131ed19d96cc40e/1hmkkvsni" 
        style={{ scale: '0.9', width: '90%', height: '80%', border: 'none',borderRadius: '20px',overflow: 'hidden' }}
        title="Live Chat"
        onLoad={() => setLoading(false)}
      ></iframe>
    </div>
  );
}

export default LiveChat;
