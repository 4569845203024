import React from 'react';
import { useUser } from './auth/AuthContext.jsx';
import Campaigns from './campaigns.jsx';
import { useTranslation } from 'react-i18next';


function WelcomePage() {
  const { user } = useUser();
  const { t } = useTranslation();

  return (
    <div className="welcomePage">
      <Campaigns />

      <h1 style={{marginTop: "50px",marginLeft: "10px"}}>{t('hello')}, <span>{user ? user.user.user.fullName : 'Guest'}</span></h1>
      <p style={{marginLeft: "10px"}}>{t('chooseYourIdealCar')}</p>
      <hr style={{marginLeft: "10px",width: "90%", backgroundColor: "#e7ff00"}}/>
      <p style={{marginLeft: "10px", color:"white"}}><i style={{color: "rgb(250, 255, 0)"}} class="fa-solid fa-gift fa-bounce"></i> {t('babyText')}</p>
    </div>
  );
}

export default WelcomePage;
