import React from 'react'
import { useTranslation } from 'react-i18next';

function PrivacyPolicies() {
  const { t } = useTranslation();

  return (
    <div style={{ color: 'white',marginTop: '75px' }} dangerouslySetInnerHTML={{ __html: t('privacy') }} />
  );
}

export default PrivacyPolicies;
