import React, { useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { UserProvider, useUser } from './components/auth/AuthContext.jsx';
import { DataProvider, useData } from './components/DataContext';


import Header from "./components/header";
import Intro from './components/intro.jsx';
import Start from './components/start.jsx';
import Signup from './components/signUp.jsx';
import CarDetail from './components/carDetail.jsx';
import Profile from './components/profile.jsx';
import Whatsapp from './components/whatsapp.jsx';
import ReserveDetail from './components/reserveDetail.jsx';
import Logout from './components/auth/logout.js';

import DeleteAccount from './components/deleteAccount.jsx';
import ForgotPassword from './components/forgotPassword.jsx';

import PrivacyPolicies from './components/privacyPolicies.jsx';

import History from './components/history.jsx';

import LiveChat from './components/liveChat.jsx';

import './App.css';
import './components/i18n.js';
import { useTranslation } from 'react-i18next';



function App() {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { loadData } = useData();
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  useEffect(() => {
    loadData(); // Verileri yükle
  }, []);


  useEffect(() => {
    // Mevcut konum /signup ise yönlendirme yapma
    if (location.pathname === '/forgotPassword' || location.pathname === '/signUp' || location.pathname === '/privacyPolicies' || location.pathname === '/deleteAccount') {
        return;
    }

    // Kullanıcı giriş yapmışsa /signup'a, değilse /intro'ya yönlendir
    if (user) {
      
    } else {
      navigate('/intro');
    }
  }, [user, navigate, location.pathname]);

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Navigate to="/intro" />} />
        <Route path="/intro" element={<Intro />} />
        <Route path="/start" element={<Start />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/carDetail/:id" element={<CarDetail />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/reserveDetail/:id" element={<ReserveDetail />} />
        <Route path="/history" element={<History />} />
        <Route path="/liveChat" element={<LiveChat />} />
        <Route path="/whatsapp" element={<Whatsapp />} />
        <Route path="/deleteAccount" element={<DeleteAccount />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/privacyPolicies" element={<PrivacyPolicies />} />
        <Route path="/logout" element={<Logout />} />
        {/* ... Diğer Route'larınız ... */}
      </Routes>


    </>
  );
}

export default function AppWrapper() {
  return (
      <DataProvider>
      <UserProvider>
        <Router>
          <App />
        </Router>
      </UserProvider>
    </DataProvider>
  );
}
