import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"; // useNavigate hook'u eklendi
import { useData } from "./DataContext";
import FadeIn from "./fadeIn";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useTranslation } from "react-i18next";

function CarDetail() {
  const [carData, setCarData] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const { id } = useParams();
  const { data } = useData();

  const { t } = useTranslation();

  const navigate = useNavigate(); // useNavigate hook'undan bir instance oluştur
  const handleReserve = () => {
    const car = data.cars.find((car) => car.id.toString() === id);
    if (car) {
      // Elementi fadeout ile animasyonla başlat
      document.getElementById("carDetailPage").classList.add("fadeout");

      // Animasyonun süresi kadar bekleyip sayfayı yönlendir
      setTimeout(() => {
        navigate(`/reserveDetail/${car.id}`);
      }, 1000); // 1000ms = 1 saniye, CSS'deki süre ile eşleşmeli
    }
  };

  useEffect(() => {
    const car = data.cars.find((car) => car.id.toString() === id);
    // if (car) {
    //   setCarData(car);
    //   try {
    //     const parsedImages = JSON.parse(car.imgJson);
    //     setImageUrls(parsedImages.gorseller);
    //   } catch (error) {
    //     console.error("Görsel parse hatası:", error);
    //     setImageUrls([]);
    //   }
    //   try {
    //     const parsedReviews = JSON.parse(car.yorumlarJson).Yorumlar;
    //     setReviews(parsedReviews);
    //     const totalRating = parsedReviews.reduce(
    //       (acc, review) => acc + parseFloat(review[2]),
    //       0
    //     );
    //     setAverageRating((totalRating / parsedReviews.length).toFixed(1));
    //   } catch (error) {
    //     console.error("Yorum parse hatası:", error);
    //     setReviews([]);
    //     setAverageRating(0);
    //   }
    // }
    if (car) {
        setCarData(car);
        try {
          const parsedImages = JSON.parse(car.imgJson);
          // Set only the first image URL if it exists
          setImageUrls(parsedImages.gorseller.length > 0 ? [parsedImages.gorseller[0]] : []);
        } catch (error) {
          console.error("Görsel parse hatası:", error);
          setImageUrls([]);
        }
        try {
          const parsedReviews = JSON.parse(car.yorumlarJson).Yorumlar;
          setReviews(parsedReviews);
          const totalRating = parsedReviews.reduce(
            (acc, review) => acc + parseFloat(review[2]),
            0
          );
          setAverageRating((totalRating / parsedReviews.length).toFixed(1));
        } catch (error) {
          console.error("Yorum parse hatası:", error);
          setReviews([]);
          setAverageRating(0);
        }
      }
      
  }, [id, data.cars]);

  const StarRating = ({ rating }) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= Math.floor(rating)) {
        stars.push(
          <i
            key={i}
            className="fa-solid fa-star"
            style={{ color: "#faff00", fontSize: "17px" }}
          />
        );
      } else if (i === Math.ceil(rating) && !Number.isInteger(rating)) {
        stars.push(
          <i
            key={i}
            className="fa-solid fa-star-half-alt"
            style={{ color: "#faff00", fontSize: "17px" }}
          />
        );
      } else {
        stars.push(
          <i
            key={i}
            className="far fa-star"
            style={{ color: "#faff00", fontSize: "17px" }}
          />
        );
      }
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignContent: "space-between",
          width: "100%",
        }}
      >
        <Popup
          trigger={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                alignContent: "space-between",
                width: "100%",
                marginTop: "30px",
              }}
            >
              <div>
                {stars}
                <span style={{ fontSize: "20px", marginLeft: "5px" }}>
                  {rating}
                </span>
                <p
                  style={{
                    fontSize: "14px",
                    margin: "0",
                    lineHeight: "17px",
                    color: "gray",
                    textAlign: "left",
                  }}
                >
                  {reviews.length} {t("reviews")}
                </p>
              </div>
              <div>
                <h1
                  style={{
                    fontSize: "30px",
                    margin: "0",
                    lineHeight: "25px",
                    textAlign: "right",
                  }}
                >
                  ${carData?.price}
                </h1>
                <p
                  style={{
                    fontSize: "14px",
                    margin: "0",
                    lineHeight: "20px",
                    color: "gray",
                    textAlign: "center",
                  }}
                >
                  per day
                </p>
              </div>
            </div>
          }
          modal
          contentStyle={{
            animation: "fadeIn 0.5s",
            backgroundColor: "black",
            padding: "20px",
            overflow: "auto",
            height: "500px",
            color: "white",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          <div>
            <h2>{t("reviews")}</h2>
            {reviews.map((review, index) => (
              <div
                key={index}
                style={{
                  borderBottom: "1px solid grey",
                  paddingBottom: "10px",
                  marginBottom: "10px",
                  color: "white",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  {Array.from({ length: review[2] }, (_, i) => (
                    <span key={i} style={{ color: "gold", marginRight: "3px" }}>
                      &#9733;
                    </span>
                  ))}
                </div>
                <b>{review[0]}</b>
                <br />
                {review[1]}
              </div>
            ))}
          </div>
        </Popup>
      </div>
    );
  };

  if (!carData) {
    return <p>{t("loadingData")}</p>;
  }

  return (
    <div className="carDetailPage" id="carDetailPage">
      <FadeIn>
        <div className="carDetailMonitor">
          {imageUrls.length > 0 ? (
            <Splide
              options={{
                type: "slide",
                perPage: 1,
                autoplay: true,
                interval: 2000,
                rewind: true,
                arrows: false,
                minHeight: "200px",
                maxHeight: "700px",
                width: "100%",
                maxWidth: "700px",
              }}
            >
              {imageUrls.map((url, index) => (
                <SplideSlide key={index}>
                  <img
                    src={url}
                    alt={`Car ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      borderRadius: "15px",
                    }}
                  />
                </SplideSlide>
              ))}
            </Splide>
          ) : (
            <p>No images available.</p>
          )}

          <div
            style={{
              padding: "5px",
              color: "white",
              fontFamily: "Poppins, sans-serif",
              textAlign: "center",
            }}
          >
            <h1 style={{ fontWeight: "900", fontSize: "2rem", margin: "0" }}>
              {carData.brand}{" "}
              <span
                style={{
                  color: "transparent",
                  WebkitTextStroke: "1px #faff00",
                }}
              >
                {carData.model}
              </span>
            </h1>
            <p
              style={{
                fontWeight: "400",
                fontSize: "13px",
                marginTop: "0px",
                marginBottom: "10px",
                color: "#787878",
              }}
            >
              {carData.year} · {carData.fuelConsumption} / 100km
            </p>
            <StarRating rating={parseFloat(averageRating)} reviews={reviews} />
            <div style={{ marginTop: "10px" }}>
              <button
                className="submitBtn"
                style={{
                  marginTop: "10px",
                  width: "100%",
                  backgroundColor: "white",
                  color: "black",
                }}
                onClick={handleReserve}
              >
                {t("reserveNow")}
              </button>
              <h4
                style={{
                  fontSize: "20px",
                  marginTop: "17px",
                  marginBottom: "0px",
                }}
              >
                {t("overview")}
              </h4>
              <p style={{ color: "gray", marginTop: "10px" }}>
                {carData.overview}
              </p>
              <div className="features" style={{ marginTop: "20px" }}>
  <h2 style={{ color: "#faff00", textAlign: "left", lineHeight: "0px", marginBottom: '30px' }}>
    {t("features")}
  </h2>

  <div style={{ display: 'flex', justifyContent: 'space-around' }}> {/* Flexbox kullanımı */}
    <div className="feature">
      <i className="fad fa-cogs"></i>
      {carData.transmission}
    </div>

    <div className="feature">
      <i className="fad fa-gas-pump"></i>
       {carData.fuelConsumption}
    </div>

    <div className="feature">
      <i className="fad fa-calendar-alt"></i>
      {carData.year}
    </div>
  </div>
</div>


              <h2
                style={{
                  color: "#faff00",
                  marginTop: "20px",
                  textAlign: "left",
                }}
              >
                {t("reviews")}
              </h2>
              {reviews.map((review, index) => (
                <div
                  key={index}
                  style={{
                    borderBottom: "1px solid grey",
                    paddingBottom: "10px",
                    marginBottom: "10px",
                    color: "white",
                    textAlign: "left",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    {Array.from({ length: review[2] }, (_, i) => (
                      <span
                        key={i}
                        style={{ color: "gold", marginRight: "3px" }}
                      >
                        &#9733;
                      </span>
                    ))}
                  </div>
                  <b>{review[0]}</b>
                  <br />
                  {review[1]}
                </div>
              ))}
            </div>
          </div>
        </div>
      </FadeIn>
    </div>
  );
}

export default CarDetail;
