import React, { createContext, useState, useContext } from 'react';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [data, setData] = useState({ cars: [] });

  const loadData = async () => {
    try {
      const response = await fetch('https://fuber.ltd/theluxapi/process.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ action: 'getCarData' }), // Tüm verileri çek
      });
      const result = await response.json();
      console.log(result);
      if (result.success) {
        setData({ cars: result.carData });
      } else {
        console.error('Veri yüklenemedi:', result.message);
      }
    } catch (error) {
      console.error('Veri çekme hatası:', error);
    }
  };

  return (
    <DataContext.Provider value={{ data, loadData }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
