import React, { useState, useEffect } from "react";
import { useUser } from "./auth/AuthContext";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

const customStyles = {
  content: {
    color: "white",
    background: "#333",
    border: "1px solid #444",
    borderRadius: "10px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "22px",
    width: "80%", // veya istediğiniz başka bir genişlik
    height: "auto", // veya istediğiniz bir yükseklik
    overflow: "auto",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)", // Overlay için koyu bir arka plan
  },
};

Modal.setAppElement("#root");

function History() {
  const { user } = useUser();
  const [bookings, setBookings] = useState([]);
  const [timeLeft, setTimeLeft] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  const [selectedBooking, setSelectedBooking] = useState(null); // Seçili rezervasyonun detayları için state

  // Modalı açan fonksiyon
  const openModal = (booking) => {
    setSelectedBooking(booking);
  };

  // Modalı kapatan fonksiyon
  const closeModal = () => {
    setSelectedBooking(null);
  };

  // Modal içeriği
  const renderModalContent = () => {
    if (!selectedBooking) return null;

    return (
      <div style={{ display: "flex", flexDirection: "column", width: "90%" }}>
        <h2
          style={{
            borderBottom: "1px solid #555",
            paddingBottom: "10px",
            margin: "0px",
          }}
        >
          {selectedBooking.brand} {selectedBooking.model}
        </h2>
        <p style={{ marginTop: "10px", fontSize: "1rem", marginBottom: "4px" }}>
          {" "}
          {t("reserveOnay")}
        </p>
        <hr style={{ color: "#555" }} />
        <p style={{ margin: "0px" }}>
          <b>{t("pickupDate")}:</b>{" "}
          {formatDate(selectedBooking.pickup_datetime)}
        </p>
        <p style={{ margin: "0px" }}>
          <b>{t("dropOffDate")}:</b>{" "}
          {formatDate(selectedBooking.dropoff_datetime)}
        </p>
        <p style={{ margin: "0px" }}>
          <b>{t("Location")}:</b> {selectedBooking.pickup_location}
        </p>
        <hr style={{ color: "#555" }} />

        <i style={{ fontSize: "0.8rem", marginTop: "0px" }}>
          Payment Status: {parseFloat(selectedBooking.bookingPrice).toFixed(2)}{" "}
          USD {selectedBooking.bookingType}
        </i>
        <i style={{ fontSize: "0.8rem", marginTop: "5px" }}>
          {t("forthetimebeing")} :)
        </i>
        <p style={{ marginTop: "10px", fontSize: "0.6rem" }}>
          {t("onthedeliverydate")}
        </p>
        <button
          onClick={() =>
            window.open(
              `https://wa.me/905448539352?text=Hello,%20I%20need%20support.%20The%20car%20I%20rented:%20${selectedBooking.brand}%20${selectedBooking.model}`,
              "_blank"
            )
          }
          style={{
            marginTop: "10px",
            padding: "10px 15px",
            fontSize: "1rem",
            border: "none",
            borderRadius: "5px",
            background: "#00ab0e",
            color: "white",
            cursor: "pointer",
          }}
        >
          <i className="fab fa-whatsapp"></i> WhatsApp Contact
        </button>
      </div>
    );
  };

  useEffect(() => {
    const fetchBookings = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://fuber.ltd/theluxapi/process.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              action: "getBookings",
              userId: user.user.user.id,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        if (result.success) {
          setBookings(result.bookings);
        } else {
          console.error(result.message);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (user && user.user && user.user.user.id) {
      fetchBookings();
    }
  }, [user.user.user.id]);

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = {};
      bookings.forEach((booking, index) => {
        const pickupDate = new Date(booking.pickup_datetime);
        const now = new Date();
        const difference = pickupDate - now;

        if (difference > 0) {
          let days = Math.floor(difference / (1000 * 60 * 60 * 24));
          let hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
            .toString()
            .padStart(2, "0");
          let minutes = Math.floor((difference / 1000 / 60) % 60)
            .toString()
            .padStart(2, "0");
          let seconds = Math.floor((difference / 1000) % 60)
            .toString()
            .padStart(2, "0");
          newTimeLeft[index] = `${
            days > 0 ? days + "g " : ""
          }${hours}:${minutes}:${seconds}`;
        } else {
          newTimeLeft[index] = "Süre Doldu";
        }
      });
      setTimeLeft(newTimeLeft);
    }, 1000);

    return () => clearInterval(timer);
  }, [bookings]);

  const formatDate = (datetime) => {
    const date = new Date(datetime);
    let hours = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    return `${date.getDate()}.${
      date.getMonth() + 1
    }.${date.getFullYear()} ${hours}:${minutes}`;
  };

  if (isLoading) {
    return (
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "black",
          color: "white",
        }}
      >
        Loading...
      </div>
    );
  }

  return (
    <div className="history">
      <Modal
        isOpen={!!selectedBooking}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Rezervasyon Detayları"
      >
        {renderModalContent()}
      </Modal>

      {bookings.map((booking, index) => (
  <div
    key={index}
    onClick={() => {
      if (timeLeft[index] !== "Süre Doldu") {
        openModal(booking);
      }
    }}
    className={`historyDiv ${
      new Date(booking.pickup_datetime) < new Date() ? "old" : "now"
    }`}
  >
    <div className="historyHeader">
      <div className="sol">
        <h3 className="ticketTitle">Car</h3>
        <p
          className="ticketP"
          style={{ fontSize: "16px", fontWeight: "500" }}
        >
          {booking.brand}
        </p>
        <p className="ticketP" style={{ fontSize: "10px" }}>
          {booking.model}{" "}
          <span style={{ fontSize: "10px" }}>{booking.year}</span>
        </p>
        <br />
        <h3 className="ticketTitle">Pick-up</h3>
        <p className="ticketP">{booking.pickup_location}</p>
      </div>

      {timeLeft[index] === "Süre Doldu" ? (
        <>
          <h3
            className="ticketTitle"
            style={{
              fontSize: "16px",
              position: "absolute",
              top: "14px",
              right: "20px",
            }}
          >
            <i className="fa-solid fa-triangle-exclamation"></i> Passed
          </h3>
          <div
            className="orta"
            style={{
              rotate: "-20deg",
              position: "absolute",
              left: "42.4%",
            }}
          >
            <p className="ticketP"></p>
          </div>
        </>
      ) : (
        <div className="orta">
          <i className="fa-solid fa-plane"></i>
          <h3 className="ticketTitle">Remaining</h3>
          <p className="ticketP">{timeLeft[index]}</p>
        </div>
      )}

      <div className="sag">
        <h3 className="ticketTitle">Rental Start</h3>
        <p className="ticketP">{formatDate(booking.pickup_datetime)}</p>
        <br />
        <h3 className="ticketTitle">Rental End</h3>
        <p className="ticketP">{formatDate(booking.dropoff_datetime)}</p>
      </div>
    </div>
  </div>
))}

    </div>
  );
}

export default History;
