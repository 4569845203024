import React, { useState, useEffect } from 'react';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";

function Campaigns() {
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await fetch('https://fuber.ltd/theluxapi/process.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ action: 'getCampaigns' }),
        });
        const data = await response.json();
        
        // API'den gelen veriyi kontrol et ve state'e ata
        if (data && data.success && data.campaigns) {
          setCampaigns(data.campaigns);
        }
      } catch (error) {
        console.error('Kampanyalar yüklenirken bir hata oluştu:', error);
      }
    };

    fetchCampaigns();
  }, []);

  return (
    <div>
       <Splide options={{
        type: "loop",
          perPage: 1,
          gap: "1rem",
          autoplay: true,
          interval: 2000, // Otomatik geçişi her 2 saniyede bir yapacak
          rewind: true,
          arrows: false,
          height: "200px",
          width: "100%",
          pauseOnHover: false,
          pauseOnFocus: false,
        }}>
        {campaigns.map((campaign) => (
          <SplideSlide key={campaign.id}>
            <div style={{ position: 'relative', height: '100%' }}>
              <img src={campaign.kampanyaGorseli} alt={campaign.kampanyaBaslik} style={{ width: '100%', height: '200px', objectFit: 'cover',borderRadius: '20px' }} />
              <div style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                padding: '20px',
                background: 'linear-gradient(to top, rgba(0,0,0,1) 30%, rgba(0,0,0,0) 100%, rgba(0,0,0,1) 100% ,transparent)',
                color: 'white',
              }}>
                <h3 style={{ margin: 0, fontSize: "1.5rem" }}>
                  {campaign.kampanyaBaslik}
                </h3>
                <p style={{ margin: 0, fontSize: "1rem" }}>
                  {campaign.kampanyaDetail}
                </p>
              </div>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}

export default Campaigns;
